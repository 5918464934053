// Single columng
.svg-bucket__parent {
  // position to the top left
  margin-top: -20%;
  margin-left: calc((80% * 0.6 * 0.6 * 9 / 16) + var(--gutter));
  margin-right: calc(-1 * var(--gutter));
  // inset
  padding-top: var(--inset);
  padding-left: var(--inset);

  @media screen and (min-width: $bp-smedium) {
    --inset: 32px;
    margin-right: 0;
    padding-right: var(--inset);
  }

  @media screen and (min-width: $bp-xl) {
    display: none;
  }
}

// Shared SVG Rules
.svg-bucket {
  display: flex;
  flex-direction: column;

  svg {
    width: 100%;
    height: auto;

    &.svg--fill {
      fill: var(--color-3);
    }

    &.svg--stroke {
      fill: none;
      stroke: var(--color-3);
    }

    path {
      fill: inherit;
      stroke: inherit;
    }
  }

  > svg {
    margin: 0.5rem 0;
  }
  > svg:first-child {
    margin-top: 0;
  }
  > svg:last-child {
    margin-bottom: 0;
  }
}

.svg-bucket--abs {
  height: 0;

  @media screen and (min-width: $bp-xl) {
    height: initial;
  }
}
