.col--1 {
  h1 {
    text-align: center;
    margin: 0;

    &:nth-child(1) {
      opacity: 0.2;
    }
    &:nth-child(2) {
      opacity: 0.4;
    }
    &:nth-child(3) {
      opacity: 0.6;
    }
    &:nth-child(4) {
      opacity: 0.8;
    }
  }
}

// add real images

// no padding
// indent picture 3
// a bp 1 64 everywhere
//
