@import "_normalize";
@import "_typography";
@import "base";

// Component Pieces
@import "wrapper";
@import "column1";
@import "picture-box";
// @import "picture-box-color";
@import "svg-bucket";
@import "text-block";

.wrapper {
  // Debug Breakpoints
  &::before {
    // content: "";
    position: fixed;
    top: 1rem;
    left: 1rem;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: rosybrown;

    @media screen and (min-width: $bp-sm) {
      background-color: teal;
    }

    @media screen and (min-width: $bp-sm) {
      background-color: var(--color-1);
    }

    @media screen and (min-width: $bp-md) {
      background-color: chartreuse;
    }

    @media screen and (min-width: $bp-lg) {
      background-color: white;
    }

    @media screen and (min-width: $bp-xl) {
      background-color: yellowgreen;
    }

    @media screen and (min-width: $bp-xxl) {
      background-color: purple;
    }
  }

  @media screen and (min-width: $bp-xl) {
    .col--1 {
      // background: rgba(200, 0, 200, 0.2);
    }
    .col--2 {
      // background: rgba(0, 200, 200, 0.2);
    }
  }
}
