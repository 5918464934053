@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@500;900&display=swap");

body {
  font-family: var(--primaryFont);
}

h1 {
  font-family: inherit;
  font-weight: 900;
  font-size: 96px;
  line-height: 1;
  color: var(--color-3);
}

h2 {
  font-family: inherit;
  font-size: 48px;
  font-weight: 900;
  line-height: 1;
  color: var(--color-1);
  margin-top: 0;
  margin-bottom: 0.5em;
}

h3 {
  font-family: inherit;
  font-weight: 900;
  font-size: 18px;
  line-height: 1.5;
  color: var(--color-4);
  margin-top: 0;
  margin-bottom: 0;
}

p {
  font-family: inherit;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  color: #777;
  color: var(--color-text);
}

a {
  color: var(--color-4);
  text-decoration: none;
  display: inline-flex;
  padding: 0;
  transition: all 0.3s ease;
  &:hover {
    background: var(--color-4);
    color: #fff;
    padding-left: 8px;
    padding-right: 8px;
  }
}

p a {
  &:hover {
    padding-left: 4px;
    padding-right: 4px;
  }
}

h3 a {
  padding-top: 8px;
  padding-bottom: 8px;
  &:hover {
    padding: 8px;
  }
}
