html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

:root {
  // Colors
  --color-1: #ef476f;
  --color-2: #ffd166;
  --color-3: #06d6a0;
  --color-4: #118ab2;
  --color-text: #181818;
  // Type
  --primaryFont: "Work Sans", sans-serif;
  // Sizing
  --pictureBoxMaxWidth: 640px;
  --pictureBoxTopPad: 10%;
  --pictureBoxBottomPad: calc(2 * var(--pictureBoxTopPad));
  --gutter: 32px;
  --inset: 16px;
}

// Variables we cant assign to root
$bp-sm: 468px;
$bp-smedium: 640px;
$bp-md: 768px;
$bp-lg: 1024px;
$bp-xl: 1280px;
$bp-xxl: 1440px;

// :root {
//   @media screen and (min-width: $bp-sm) {
//     --inset:
//   }
// }
