.wrapper {
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  padding-top: 1.5rem; // 24
  // merge
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media screen and (min-width: $bp-smedium) {
    padding: 3rem;
  }

  @media screen and (min-width: $bp-xl) {
    padding: 4rem;
    flex-direction: row;
  }
}

.col {
  --colPadding: 32px;
  --colBasis: calc(var(--pictureBoxMaxWidth) + (var(--colPadding) * 2));
  max-width: var(--pictureBoxMaxWidth);
  flex: 0 1 50%;

  &.col--1 {
    flex-shrink: 2;
  }

  &.col--2 {
  }

  @media screen and (min-width: $bp-xl) {
    padding: 0 var(--colPadding);
    flex-basis: var(--colBasis);
    max-width: none;
  }

  @media screen and (min-width: $bp-xxl) {
    --colPadding: 64px;
  }
}
