.picture-box__parent {
  width: 100%;
  min-height: 200px;

  @media screen and (min-width: $bp-lg) {
    max-width: var(--pictureBoxMaxWidth);
    margin-left: 0;
  }
}

.picture-box__wrapper {
  width: 100%;
  max-width: var(--pictureBoxMaxWidth);
  padding: 0;
  padding-top: var(--pictureBoxTopPad);
  padding-bottom: var(--pictureBoxBottomPad);
  position: relative;
}

.picture-box {
  --ratioLandscape: calc(9 / 16 * 100%);
  --ratioPortrait: calc(16 / 9 * 100%);

  &--1 {
    width: 80%;
  }

  &--2 {
    width: calc(80% * 0.6);
    position: absolute;
    top: 0;
    right: 0;
  }

  &--3.picture-box {
    width: calc(80% * 0.6 * 0.6 * 9 / 16);
    position: absolute;
    bottom: 0;
    left: var(--gutter);

    .box {
      padding-top: var(--ratioPortrait);
    }

    @media screen and (min-width: $bp-xl) {
      left: initial;
      right: calc(80% * 0.6);
      transform: translate3d(50%, 0, 0);
    }
  }
}

.picture-box .box {
  position: relative;
  overflow: hidden;
  padding-top: var(--ratioLandscape);

  img {
    position: absolute;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    top: 0;
  }
}
